import { Route, Redirect, Switch } from "react-router-dom";
import React, { Suspense, lazy, Fragment } from "react";

import { ToastContainer } from "react-toastify";

const Login = lazy(() => import("../../DemoPages/Login"));
const Signup = lazy(() => import("../../DemoPages/Signup"));
const Forgot = lazy(() => import("../../DemoPages/Forgot"));
const Reset = lazy(() => import("../../DemoPages/Reset"));
const Bot = lazy(() => import("../../DemoPages/Bot"));
const Success = lazy(() => import("../../DemoPages/Success"));
const Cancel = lazy(() => import("../../DemoPages/Cancel"));
const NotFound = lazy(() => import("../../DemoPages/NotFound"));

const AppMain = () => {
  return (
    <Fragment>
      {/* Components */}

      <Suspense
        fallback={
          <div className="loader-container loader_custom">
            <div className="loader-container-inner">
              <h6 className="mt-5">
                <div class="float-left mr-3 mb-3">
                  <div class="loader loader-undefined loader-active">
                    <div class="loader-inner ball-clip-rotate">
                      <div></div>
                    </div>
                  </div>
                </div>
              </h6>
            </div>
          </div>
        }
      >
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/signup" component={Signup} />

          <Route path="/forgot" component={Forgot} />
          <Route path="/reset" component={Reset} />
          <Route path="/bot" component={Bot} />
          <Route path="/success" component={Success} />
          <Route path="/cancel" component={Cancel} />
          <Route component={NotFound} />
        </Switch>
      </Suspense>

      <Route exact path="/" render={() => <Redirect to="/bot" />} />
      <ToastContainer />
    </Fragment>
  );
};

export default AppMain;
