import React, { useState,useContext,createContext ,useEffect} from 'react';


const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {

  const [user, setUser] = useState(null);


  useEffect(() => {
    
    const authData = JSON.parse(localStorage.getItem("neece_auth"));
    if (authData) {
    
      setUser(authData);
    }
  }, []);


  const login=(user)=>{
  
    setUser(user);
    
    localStorage.setItem("neece_auth", JSON.stringify(user));
  
  }
  

 const logout=()=>{
    setUser(null);
    localStorage.removeItem("neece_auth");
  }

 

  return (
    <AuthContext.Provider value={{ user,login,logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth=()=>{
  return useContext(AuthContext);
};